import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';

// Fetch all FormAccesses
// export const fetchFormAccesses = () => {
//     return axios.get(`${BASE_URL()}/api/v1/form_accesses`, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': storage.get('token')
//         }
//     }).then(response => response.data);
// };

// Fetch a single FormAccess by ID
export const fetchFormAccess = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/form_accesses/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Create a new FormAccess
// export const createFormAccess = (formAccessData) => {
//     return axios.post(`${BASE_URL()}/api/v1/form_accesses`, formAccessData, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': storage.get('token')
//         }
//     }).then(response => response.data);
// };

// Update an existing FormAccess
// export const updateFormAccess = (id, formAccessData) => {
//     return axios.put(`${BASE_URL()}/api/v1/form_accesses/${id}`, formAccessData, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': storage.get('token')
//         }
//     }).then(response => response.data);
// };

// Delete a FormAccess
// export const archiveFormAccess = (id) => {
//     return axios.delete(`${BASE_URL()}/api/v1/form_accesss/${id}`, {
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': storage.get('token')
//         }
//     }).then(response => response.data);
// };