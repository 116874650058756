import React, { useEffect, useState } from "react";
import { Link, browserHistory } from "react-router";
import Select from "react-select";
import { FormTemplateProps, form_roles } from "../../formSchema";
import { isAdmin } from "../../utils/utils";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import * as FormTemplateActions from "../../actions/formTemplateAction";
import * as FormInstanceActions from "../../actions/formInstanceAction";
import { formInstanceConstants}from "../../constants/formInstance.constants";
import swal from "@sweetalert/with-react";
import LoadingMessage from "../LoadingMessage/LoadingMessage";

interface StateProps {
    user: any
    userList: any[];
    formTemplates: FormTemplateProps[];
}

interface DispatchProps {
    formTemplateActions: typeof FormTemplateActions;
    formInstanceActions: typeof FormInstanceActions;
}

interface Props extends StateProps, DispatchProps {}


const MemberRow = ({ user, removeUser, updateUserRole  }) => {

    const handleRoleSelect = (e) => {
        updateUserRole(user, e);
    };

    const handleUserRemoval = () => {
        removeUser(user);
    }

    return (
        <tr>
            <td>
                <p className='mb-0'>
                    <span className='font-weight-bold'>{user.first_name}</span> -{" "}
                    <span className='text-muted'>{user.email}</span>
                </p>
            </td>
            <td>
                <Select
                    onChange={handleRoleSelect}
                    options={form_roles.map((role) => ({
                        value: role,
                        label: role,
                    }))}
                    placeholder='-Select-'
                    className='form-group__field'
                />
            </td>
            {/* <td>
                                <button type='button' className='btn border-primary text-primary'>
                                    <span className='mr-1 fa-stack' style={{ fontSize: "0.5rem" }}>
                                        <i className='fas fa-circle fa-stack-2x'></i>
                                        <i className='fas fa-plus fa-stack-1x fa-inverse'></i>
                                    </span>
                                    Assign
                                </button>
                            </td> */}
            <td className="pl-3">
                <i className='cursor-pointer fa-solid fa-trash' onClick={handleUserRemoval} />
            </td>
        </tr>
    );
};

const MemberTable = ({ children }) => {
    return (
        <div className='p-4 my-4 bg-white shadow-sm'>
            <table className='w-100'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        {/* <th>Tasks</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export const CreateSOPFormPage = ({ user, userList, formTemplates, formTemplateActions, formInstanceActions }: Props) => {
    const [selectedForm, setSelectedForm] = useState<any>(null);
    const [startDate, setStartDate] = useState(null);
    const [formUsers, setFormUsers] = useState<any[]>([]);

    useEffect(() => {
        formTemplateActions.fetchFormTemplates();
    }, []);

    // If not an admin, return an error
    if (!isAdmin(user)) {
        <p>Access denied</p>;
    }

    const handleFormSelect = (e) => {
        setSelectedForm(e);
    };
    const handleFormStartDate = (e) => {
        setStartDate(e.target.value);
    };

    const removeUser = (user) => {
        const updatedUsers = formUsers.filter((u) => u.id !== user.id);
        setFormUsers(updatedUsers);
    }

    const updateUserRole = (user, role) => {
        const updatedUsers = formUsers.map((u) => {
            if (u.id === user.id) {
                return { ...u, form_role: role.value.toLowerCase() };
            }
            return u;
        });

        setFormUsers(updatedUsers);
    }

    const handleUserSelect = (selectList) => {
        setFormUsers(selectList);
    };

    const validateUserRoles = () => {
        // Validate user roles
        if (formUsers.length > 0) {

            // Confirm all users have a role
            const roles = formUsers.filter((user) => user.form_role);
            if (roles.length !== formUsers.length) {
                // alert("Please select a role for all users");
                swal("Please select a role for all users");
                return ;
            }

            // Confirm we have an operator
            if (!roles.find((user) => user.form_role === 'operator')) {
                swal("Please select an operator");
                return;
            }

            // ? What if we have an admin only?
            if (["operator", "approver"].find((role) => !roles.find((user) => user.form_role === role))) {
                swal("Please select an approver or admin");
                return;
            }

            return true;
        }
    }

    const createFormInstance = async (formDetails: any) => {
        swal(
            <i className="fa fa-cog fa-spin fa-5x p-5" />, {
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
          className: 'error-handler-modal'
        })


        // Todo Type Properly
        const fetchRes = await formInstanceActions.createFormInstance(formDetails) as any;


        if (fetchRes.action.type === formInstanceConstants.CREATE_FORM_INSTANCE_FULFILLED) {
            formInstanceActions.fetchFormInstances();
            browserHistory.push('/forms');
            swal.close();
        } else {
            // Handle error
            swal({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while creating the form instance. Please try again later.',
                className: 'error-handler-modal'
            });
            console.log('error', fetchRes)
        }
    }

    const handleSubmit = async () => {
        // Todo setup proper error display

        // Validate form fields filled out
        if (!selectedForm || !startDate || formUsers.length === 0) {
            alert("Please fill out all fields");
            return;
        }


        // Validate user roles
        if (!validateUserRoles()) return;


        // Define form access roles
        const formAccess = {}
        const admins = formUsers.filter((user) => user.form_role === 'admin');
        const approvers = formUsers.filter((user) => user.form_role === 'approver');
        const operators = formUsers.filter((user) => user.form_role === 'operator');

        // Prevent undefined values
        if (admins.length > 0) {
            formAccess['admin'] = admins.map(a => a.id);
        }
        if (approvers.length > 0) {
            formAccess['approver'] = approvers.map(a => a.id);
        }
        if (operators.length > 0) {
            formAccess['operator'] = operators.map(a => a.id);
        }

        createFormInstance({
            form_template_id: selectedForm.id,
            organization_id: selectedForm.organization_id,
            start_date: (new Date(startDate)).toISOString(),
            form_access: formAccess
          })
    };

    if (!formTemplates) {
        return <div>Loading...</div>;
    }

    if (formTemplates.length <= 0) {
        return <div>No form templates found</div>;
    }

    return (
        <main className='app-content'>
            <section className='emp_org_settings_sec emp_report_sec emp_circle_box_sec'>
                <div className='my-5 row'>
                    <div className='col-1' />
                    <div className='col-sm-9'>
                        {/* Main Content */}

                        {/* Heading */}
                        <div className='pb-2 mx-0 app-title border-bottom w-100'>
                            <div>
                                <h1 className='font-weight-bold'>New Form</h1>
                                <p className='mb-0'>Please select a form to fill out</p>
                            </div>
                        </div>

                        {/* Form Selection Card */}
                        <div className='pb-4 my-4 border-bottom'>
                            <div className='p-4 bg-white shadow-sm'>
                                <div>
                                    <h2>Form Name</h2>
                                    <Select
                                        onChange={handleFormSelect}
                                        options={formTemplates.map((template) => ({
                                            ...template,
                                            value: template.formula_code,
                                            label: "Batch Ticket - " + template.formula_code,
                                        }))}
                                        placeholder='-Select-'
                                        className='form-group__field'
                                    />
                                </div>

                                {/* Start Date  */}
                                <div className='mt-4'>
                                    <h2>Start Date</h2>
                                    <p>
                                        <input
                                            onChange={handleFormStartDate}
                                            type='date'
                                            className='form-group__field'
                                            // Today
                                            min={new Date().toISOString().split("T")[0]}
                                            // 14 days from now
                                            max={new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Member Selection */}
                        {selectedForm && startDate && (
                            <div>
                                <h2>Add Members</h2>
                                <p>
                                    Invite existing or new users to access this form and select their role.
                                    <br />
                                    <i>*Each form requires at least one Operator and either one Approver or one Admin.</i>
                                </p>
                                <Select
                                    isMulti
                                    isSearchable
                                    onChange={handleUserSelect}
                                    options={userList.map((user) => ({
                                        ...user,
                                        value: user.id,
                                        label: user.email,
                                    }))}
                                    value={formUsers}
                                    placeholder='Select members'
                                    className='form-group__field'
                                />
                            </div>
                        )}

                        {/* Member Table */}
                        {formUsers.length > 0 && (
                            <MemberTable>
                                {formUsers.map((user) => (
                                    <MemberRow
                                        key={user.id}
                                        user={user}
                                        removeUser={removeUser}
                                        updateUserRole={updateUserRole}
                                    />
                                ))}
                            </MemberTable>
                        )}

                        {/* Action Buttons */}
                        <div className="mt-4">
                            <Link to="./" onClick={() => browserHistory.goBack()} className='btn btn-ghost'>
                                Cancel
                            </Link>
                            {formUsers.length > 0 && (
                                <button type='button' className='ml-2 btn btn-primary' onClick={handleSubmit}>
                                    Send Invitation
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='col-2' />
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = (state, ownProps): StateProps => ({
    ...ownProps,
    userList: state.userReducer.userList,
    formTemplates: state.formTemplateReducer
});


const mapDispatchToProps = (dispatch): DispatchProps => ({
    formTemplateActions: bindActionCreators(FormTemplateActions, dispatch),
    formInstanceActions: bindActionCreators(FormInstanceActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(CreateSOPFormPage);
