import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'


import { FieldInstanceProps, FormInstanceProps, FormTemplateProps } from "../../formSchema";
import { Instruction, round, floor, ceil } from "./FormFields/Instruction";
import { Heading } from "./FormFields/Heading";
import { ProcessPoint } from "./FormFields/ProcessPoint";
import { TemplateValue } from "./FormFields/TemplateValue";
import { Checkbox } from "./FormFields/Checkbox";
import Signature from "./FormFields/Signature";
import { TextArea } from "./FormFields/TextArea";
import { SOPSelect } from "./FormFields/Select";
import { FieldError } from "./FormFields/FieldError";
import { DateButton } from "./FormFields/DateButton";

type FieldProps = {
    formInstance: FormInstanceProps;
    instance: FieldInstanceProps;
    template: FormTemplateProps;
    onChange: ({ id, value }: { id: string; value: string }) => void;
    hideLabel?: boolean;
    classes?: string;
};

export const horizontalClasses = "form-input--horizontal";

// We need the instance to update the value on the db
// Template for template fields
// OnChange is the action for updating fields
// StandardYield is for deviation percent validation
// Hide label is for hiding the label
// Classes for columns and styling
export const FieldTypes = ({
    formInstance,
    instance,
    template,
    onChange,
    hideLabel = false,
    classes = ""
}: FieldProps) => {
    const standardYield = formInstance.standard_yield.value;
    const { field } = instance;
    const [fieldVal, setFieldVal] = useState(instance.value || "");
    const [error, setError] = useState(instance.error || "");
    const validation = field.validation_type && field.validation_value && {
        validationType: field.validation_type,
        validationValue: field.validation_value,
    }

    useEffect(() => {
        setError(instance.error || "");
    }, [instance.error]);


    const validateInput = (e) => {
        if (validation) {
            // console.log('validation', validation)
            const { validationType, validationValue } = validation;
            const val = e.target.value;

            if (validationType === "min") {
                if (val < validationValue[0]) {
                    setError("Value too low, min is " + validationValue[0]);
                    return
                }
            }
            if (validationType === "max") {
                if (val > validationValue[0]) {
                    setError("Value too high, max is " + validationValue[0]);
                    return
                }
            }
            if (validationType === "range") {
                if (val < validationValue[0] || val > validationValue[1]) {
                    setError("Value out of range," + validationValue[0] + " - " + validationValue[1]);
                    return;
                }
            }
            if (validationType === "deviation-percent") {
                // Check if standardYield is available
                if(!standardYield) return;

                const [value, deviation] = validationValue;
                const lowerLimit = floor(parseFloat(standardYield) * (value - deviation) / 100)
                const upperLimit = ceil(parseFloat(standardYield) * (value + deviation) / 100)
                if (val < lowerLimit || val > upperLimit) {
                    setError("Value out of deviation range " + validationValue[1] + "%");
                    return;
                }
            }

            // Clear error
            setError("");
        }
    }

    // Need to update field_instances
    const updateInstance = (e) => {
        // Use default value
        let val = instance.value;

        // e is for select and signature inputs
        if (typeof e === 'string') {
            val = e;
            setFieldVal(e);
        } else {
            val = e.target.value;
            setFieldVal(val);
        }

        onChange({
            id: instance.id,
            value: val,
        });
    };


    // Heading
    if (field.field_type === "heading") return <Heading classes={classes} value={field.field_name} />;
    if (field.field_type === "heading-bold")
        return <Heading classes={classes + " font-weight-bold"} value={field.field_name} />;

    // Instructions
    if (field.field_type === "instruction" && standardYield) {
        return (
            <Instruction
                value={field.field_name}
                systemOfUnits={template?.system_of_units}
                standardYield={standardYield}
            />
        );
    }

    // Note
    if (field.field_type === "note") return <p>{field.field_name}</p>;

    // Process Point
    if (field.field_type === "process-point")
        return <ProcessPoint value={field.field_name} description={field.field_description} />;

    // Template field
    if (field.field_type === "template" && field.template_value) {
        return <TemplateValue name={field.field_name} value={template[field.template_value]} classes={classes} />;
    }

    // Checkbox
    if (field.field_type === "checkbox") {
        return (
            <Checkbox
                label={!hideLabel ? field.field_name : ''}
                value={fieldVal}
                orientation={field.orientation}
                onChange={updateInstance}
                classes={classes}
            />
        );
    }


    // Date time, date, or time
    if (['datetime-btn', 'date-btn', 'time-btn'].includes(field.field_type)) {
        return (
          <DateButton
            label={!hideLabel ? field.field_name : ''}
            fieldType={field.field_type}
            value={fieldVal}
            onChange={updateInstance}
            classes={classes}
          />
        )
    }

    // Signature
    if (["signature", "signature-role"].includes(field.field_type)) {
        return (
            <Signature
                label={!hideLabel ? field.field_name : ''}
                description={field.field_description}
                orientation={field.orientation}
                classes={classes}
                updateInstance={updateInstance}
                value={fieldVal}
                instance={instance}
                error={error}
                showRole={field.field_type === "signature-role"}
                timestamp={instance.updated_at}
            />
        );
    }

    // Textarea
    if (field.field_type === "textarea") {
        return (
            <TextArea
                label={!hideLabel ? field.field_name : ''}
                value={fieldVal}
                orientation={field.orientation}
                onChange={updateInstance}
                classes={classes}
            />
        );
    }

    // Select
    if (field.field_type === "select" && field.field_options) {
        return (
            <SOPSelect
                value={fieldVal}
                options={field.field_options}
                onChange={updateInstance}
                label={!hideLabel ? field.field_name : ''}
                orientation={field.orientation}
                classes={classes}
                error={error}
                />
        );
    }

    // Default
    return (
      <div className={` my-4 px-0 ${classes || 'col-sm-6'}`}>
        <FieldError error={error} className={field.orientation || 'vertical'} />
        <div
          className={`form-input ${
            field.orientation === 'horizontal' && horizontalClasses
          }`}
        >
          {field.field_name && !hideLabel && <label>{field.field_name}:</label>}
            <input
              type={field.field_type}
              value={fieldVal}

              min={getMinValue(field)}
              className={`form-control form-control-input ${
                error && 'border-danger'
              }`}
              onChange={updateInstance}
              onBlur={validateInput}
            />
        </div>
      </div>
    )
};

const getMinValue = (field) => {
    if (field.validation_type === "min") {
        return field.validation_value[0];
    }
    else if (field.validation_type === "range") {
        return field.validation_value[0];
    }
    else if (field.field_type === "number") {
        return 0;
    }
    else if (field.field_type === "date") {
        return new Date().toISOString().split("T")[0];
    }
    return undefined;
}

const mapStateToProps = (state) => ({
    formInstance: state.formInstanceReducer.activeForm,
    template: state.formTemplateReducer[0],
});

export default connect(mapStateToProps)(FieldTypes)
