import React, { useState } from "react";


export const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="my-4">
            <button onClick={toggleAccordion} className='btn mb-2 py-1 px-2 accordian-title'>
                <i className={`mr-2 fa fa-angle-down fa-lg accordian-icon ${!isOpen ? 'rotate-90' : ''}`}></i> {title}
            </button>
            {isOpen && children}
        </div>
    );
};