import React, { useEffect, useMemo, useState } from 'react'
import { isAdmin } from '../../utils/utils'
import { FormInstanceProps, FormTemplateProps } from '../../formSchema'
import '../../styles/SOPForms.css'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as FormTemplateActions from '../../actions/formTemplateAction'
import * as FormInstanceActions from '../../actions/formInstanceAction'
import { formInstanceConstants } from '../../constants/formInstance.constants'

import FormHeader from './FormHeader'
import { AdjustmentNotification } from './Notification'
import FormSection from './FormSection'
import * as FormAccessActions from '../../actions/formAccessAction'
import { browserHistory } from 'react-router'
import LoadingMessage from '../LoadingMessage/LoadingMessage'

type StateProps = {
  user: any
  org: any
  params: { [key: string]: any }
  formTemplate: FormTemplateProps
  formInstance: FormInstanceProps
}

type DispatchProps = {
  formTemplateActions: typeof FormTemplateActions
  formInstanceActions: typeof FormInstanceActions
}

type Props = StateProps & DispatchProps

export const SOPFormPage = ({
  user,
  formTemplate,
  formInstance,
  params,
  formInstanceActions,
  formTemplateActions
}: Props) => {
  const adminUser = isAdmin(user)
  const [userAccess, setUserAccess] = useState(false)

  const [loading, setLoading] = useState(true)

  // Fetch form instance
  const fetchInstance = async () => {
    const instanceRes = (await formInstanceActions.fetchFormInstance(
      params.id
    )) as any
    const instance = instanceRes.value

    // Confirm form instance is fetched
    if (
      instanceRes.action.type ===
      formInstanceConstants.FETCH_FORM_INSTANCE_FULFILLED
    ) {
      // Update form template
      formTemplateActions.setFormTemplate(instance.form_template)

      // Hide loading
      setLoading(false)
    }
  }

  // Fetch form instance on mount
  useEffect(() => {
    fetchInstance()
  }, [])

  // Confirm user access
  useEffect(() => {
    // Confirm instance and template are loaded
    if (formInstance) {
      // Get user access roles
      const memberRes = FormAccessActions.fetchFormAccess(formInstance.id)
      memberRes.payload.then((members) => {
        setUserAccess(members.find((mem) => mem.user_id === user.user_id))
      })
    }
  }, [formInstance, formTemplate])

  // Submit form
  const submitForm = async () => {
    // TODO: Decide if form is completed or rejected
    const status = 'completed'
    // Save Form to DB
    await formInstanceActions.updateFormInstance(formInstance.id, {
      status: status
    })

    browserHistory.push('/forms')
  }

  if (loading) {
    return (
      <div className="app-content">
        <LoadingMessage />
      </div>
    )
  }

  // If form data is not loaded
  if (!formInstance || !formTemplate) {
    return (
      <div id="sops" className="app-content">
        Form not found
      </div>
    )
  }

  // If user is not admin and user is not in the form access list
  if (!adminUser && !userAccess) {
    return <div className="app-content">Access Denied</div>
  }

  return (
    <main
      id="sops"
      className={`app-content d-flex flex-column `}
      // ${activeSection && activeSection.section_name === "Batch Information" ? "batch-info" : "" }
    >
      {formInstance.adjustment_required && <AdjustmentNotification />}

      <FormHeader />

      <FormSection submitForm={submitForm} />
    </main>
  )
}

const mapStateToProps = (state, ownProps): StateProps => ({
  ...ownProps,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  formTemplateActions: bindActionCreators(FormTemplateActions, dispatch),
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SOPFormPage)
