import React, { useEffect, useMemo, useState } from "react";
import { Link, browserHistory } from "react-router";


import { bindActionCreators } from "redux";
import { connect } from "react-redux";


import TableBody, { TableHeader } from "./Table";

import { isAdmin } from "../../utils/utils";
import { FormInstanceProps } from "../../formSchema";
import * as FormInstanceActions from "../../actions/formInstanceAction";

import "../../styles/SOPDashboard.css";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import { Accordion } from "./Accordian";
import { userData } from "../../utils/fetchUserData";

interface StateProps {
    user: any;
    formInstances: FormInstanceProps[];
}

interface DispatchProps {
    actions: any;
}

interface Props extends StateProps, DispatchProps {}


const NewFormButton = () => (
    <div className='col-sm-12'>
        <div className='row align-items-start' style={{ marginTop: "20px" }}>
            <Link to='/forms/assign'>
                <button className='btn btn-primary emp-type-btn-primary'>
                    <i className='mr-1 fa fa-plus' />
                    New Form
                </button>
            </Link>
        </div>
    </div>
);


export const SOPDashboardPage = ({user, formInstances, actions}: Props) => {

    const adminUser = isAdmin(user);

    // TODO: Resolve from redux
    // const [loading, setLoading] = useState(true);

    useEffect(() => {

        // TODO: Reset any form state
        // FormTemplate
        // FormInstance
        // FormSection
        // FieldInstance
        adminUser && userData()

        actions.fetchFormInstances();
    }, []);


    // TODO : use redux
    const removeInstance = async (id: string) => {
        // FormInstanceActions.deleteFormInstance(id);
        await actions.archiveFormInstance(id);
        // setFormInstances(prev => prev.filter(instance => instance.id !== id));
    }

    console.log('formInstances', formInstances)
    const newForms = useMemo(() => formInstances.filter((form) => form.status === 'ready'), [formInstances]);
    const inProgressForms = useMemo(() => formInstances.filter((form) => form.status === "in_progress"), [formInstances]);
    const completedForms = useMemo(() => formInstances.filter((form) => form.status === "completed"), [formInstances]);
    const rejectedForms = useMemo(() => adminUser && formInstances.filter((form) => form.status === "rejected"), [formInstances]);

    if (!formInstances) {
        return <LoadingMessage />
    }


    return (
        <main className='app-content sop-dashboard'>
            <section className='emp_org_settings_sec emp_report_sec emp_circle_box_sec'>
                <div className='py-2 row border-bottom d-flex justify-content-between'>
                    <div className='ml-4' style={{ color: "black", fontSize: "1.5em" }}>
                        All Forms
                    </div>
                    <div className='filter-options'>
                        {/* <span className='mr-4'>
                            <i className='fa fa-filter' /> Filter
                        </span>
                        <span className='mr-4'>
                            <i className='fa fa-sort' /> Sort
                        </span> */}
                        <button className='mr-2 btn btn-outline-secondary'>
                            <i className='fa fa-file-export' /> Export
                        </button>
                    </div>
                </div>

                {adminUser && <NewFormButton />}

                {newForms.length > 0 && (
                    <Accordion title='Ready to Start'>
                        <div className='shadow'>
                            <TableHeader adminUser={adminUser} />
                            <TableBody removeInstance={removeInstance} formList={newForms} adminUser={adminUser} />
                        </div>
                    </Accordion>
                )}

                {inProgressForms.length > 0 && (
                    <Accordion title='In Progress'>
                        <div className='shadow'>
                            <TableHeader adminUser={adminUser} />
                            <TableBody removeInstance={removeInstance} formList={inProgressForms} adminUser={adminUser} />
                        </div>
                    </Accordion>
                )}

                {completedForms.length > 0 && (
                    <Accordion title='Completed'>
                        <div className='my-3 shadow'>
                            <TableHeader adminUser={adminUser} />
                            <TableBody removeInstance={removeInstance} formList={completedForms} adminUser={adminUser} />
                        </div>
                    </Accordion>
                )}

                {rejectedForms && rejectedForms.length > 0 && (
                    <Accordion title='Rejected'>
                        <div className='my-3 shadow'>
                            <TableHeader adminUser={adminUser} />
                            <TableBody removeInstance={removeInstance} formList={rejectedForms} adminUser={adminUser} />
                        </div>
                    </Accordion>
                )}

                {!formInstances.length && <div className='mt-5 text-center'>No forms available</div>}
            </section>
        </main>
    );
};
const mapStateToProps = (state, ownProps): StateProps => ({
    ...ownProps,
    formInstances: state.formInstanceReducer.instances,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(FormInstanceActions, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(SOPDashboardPage);