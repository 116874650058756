import {formInstanceConstants} from '../constants/formInstance.constants';
import { FormInstanceProps } from '../formSchema';



interface FormInstanceReducer {
    instances: FormInstanceProps[],
    activeForm: FormInstanceProps,
}

const initialState: FormInstanceReducer = {
    instances: [],
    activeForm: {} as FormInstanceProps,
};

export const formInstanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case formInstanceConstants.FETCH_FORM_INSTANCES_FULFILLED:
        state = {
          ...state,
          instances: action.payload,
        }
        return state

      // This is for one singular form instance
      case formInstanceConstants.FETCH_FORM_INSTANCE_FULFILLED:
        state = {
          ...state,
          activeForm: action.payload,
        }
        return state

      case formInstanceConstants.UPDATE_FORM_INSTANCE_FULFILLED:
        return state

      case formInstanceConstants.ARCHIVE_FORM_INSTANCE_FULFILLED:
        return {
          ...state,
          instances: state.instances.filter(
            (formInstance) => formInstance.id !== action.payload.id
          )
        }

      case formInstanceConstants.CREATE_FORM_INSTANCE_FULFILLED:
        return state

    case formInstanceConstants.FETCH_FORM_INSTANCE_SECTION_FULFILLED:
        state = {
          ...state,
          activeForm: {...state.activeForm, section: action.payload}
        }
        return state

      default:
        return state
    }
};