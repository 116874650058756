

export const form_roles: string[] = [
    "Admin",
    "Operator",
    "Approver",
];

interface formTemplateStatus {
    id: number,
    name: string
}

export const form_template_status: formTemplateStatus[] = [
    { id: 1, name: "active" },
    { id: 2, name: "inactive" },
];

export const form_status = [
    { id: 1, name: "Ready to start" },
    { id: 2, name: "In Progress" },
    { id: 3, name: "Complete" },
    { id: 4, name: "rejected" },
];

interface formAccess {
    id: number,
    users: number[],
    form_role_id: number,
    form_instance_id: number,
}


export interface FormTemplateProps {
    id: number,
    organization_id: number,
    product_name: string,
    product_description: string
    product_number: string,
    formula_code: string,
    create_at: string,
    version: string,
    status: string,
    system_of_units?: string,
    temperature_unit?: string,
    sections: FormSectionProps[],
}


export interface FormSectionProps {
    id: string,
    section_name: string,
    fields: number[],
    order_index: number,
    section_description?: string,
    batch_adjustment?: boolean,
    show_adjustment_btn?: boolean,
}


// Field Types:
// - Group
// - Text
// - Number
// - Date
// - Time
// - Datetime-local
// - Select
// - Textarea
// - Signature
// - Read-only

export interface FormFieldProps {
    id: string,
    field_name: string,
    field_description?: string,
    field_type: string,
    section_id: string,
    field_options?: {value: string[]},
    field_index?: number,
    is_required?: boolean,
    orientation?: string,
    field_label?: string,
    template_value?: string,
    parent_id?: string,
    validation_type?: string,
    validation_value?: number[],
    parent?: FormFieldProps,
    children?: FormFieldProps[],
}

export interface FormInstanceProps {
    id: string,
    organization_id: number,
    form_template_id: number,
    created_by: number,
    created_at: string,
    updated_by: number,
    updated_at: string,
    archived_by: number | null,
    archived_at: string | null,
    status: string,
    version: number,
    adjustment_required?: boolean,
    work_center?: FieldInstanceProps,
    lot_number?: FieldInstanceProps,
    standard_yield: FieldInstanceProps,
    // field_instances: FieldInstanceProps[],
    section?: FieldInstanceProps[],
}

export interface FieldInstanceProps {
    id: string,
    value: string,
    form_instance_id: number,
    field: FormFieldProps,
    field_id: number,
    created_by: number,
    created_at: string,
    updated_by: number,
    updated_at: string,
    validation_type?: string,
    validation_value?: number[],
    error?: string,
    children?: FieldInstanceProps[],
}
