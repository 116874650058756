import React from "react";
import { Link } from "react-router";
import swal from '@sweetalert/with-react'
import { connect } from "react-redux";

import * as FormAccessActions from "../../actions/formAccessAction";
import * as FormInstanceActions from "../../actions/formInstanceAction";

import { StatusComponent } from "./FormStatus";
import { MemberRow, MemberTable } from "../CreateSOPFormPage/MembersTable";
import Select from "react-select";

interface TableElProps {
    className?: string;
    children: React.ReactNode;
}

const TableCol = ({ className = "", children }: TableElProps) => {
    return <div className={`col ${className}`}>{children}</div>;
};

const LinkCol = ({ to, children }: { to: string; children: React.ReactNode }) => (
    <>
    <TableCol>
        <Link to={to} className='text-body'>
            {children}
        </Link>
    </TableCol>
    </>
);

const TableRow = ({ className = "", children }: TableElProps) => {
    return <div className={`row row-cols-6 p-4 align-items-center ${className}`}>{children}</div>;
};


export const TableHeader = ({ adminUser }: { adminUser: boolean }) => (
    <TableRow className='bg-light'>
        <TableCol className='checkbox-col th'>
            <>
            {/* TODO: Handle checking multiple items */}
            {/* <input type='checkbox' /> */}
            </>
        </TableCol>
        <TableCol className='th'>Date created</TableCol>
        <TableCol className='th'>Product</TableCol>
        <TableCol className='th'>Form Name</TableCol>
        <TableCol className='th'>Status</TableCol>
        {adminUser && <TableCol className='action-col th'>Actions</TableCol>}
    </TableRow>
);

const FormMembers = ({formUsers}) => {
  return (
    <div className="text-left">
      <h2>Form Members</h2>
      {formUsers.map((member) => (
        <div className="d-flex justify-content-between border-top align-items-center px-2 py-1">
          <div>
            <p className="mb-0 font-weight-bold">
              {member.first_name} {member.last_name}
            </p>
            <p className="mb-0 text-muted">{member.email}</p>
          </div>

          <p>{member.form_role}</p>
        </div>
      ))}
    </div>
  )
}


interface StateProps {
    removeInstance: (formId: string) => void;
    adminUser: boolean;
    formList: any;
    userList: any;
}

export const TableBody = ({ removeInstance, formList, adminUser, userList }) => {


    const archiveForm = (formId: string) => {
        return swal({
            icon: 'warning',
            title: 'Delete form instance.',
            text: 'Are you sure you want to delete this form instance?',
            buttons: ["Cancel", "Delete"],
            className: 'error-handler-modal'
        }).then((value) => {
            if (value) {
                    // update form Instances
                    removeInstance(formId).then(() => {
                        swal("Your form instance has been deleted!", {
                            icon: "success",
                        });
                });
            }
        })
    }

    const formMembers = (formId: string) => {
        const memberRes = FormAccessActions.fetchFormAccess(formId);
        memberRes.payload
          .then((members) => {
            const formUsers = members.map((member) => {
              const user = userList.find((user) => user.id === member.user_id)
              return { ...user, form_role: member.form_role }
            })
            return swal(<FormMembers formUsers={formUsers} />)
          })
          .catch((error) => {
            return swal({
              icon: 'error',
              title: 'Error fetching form members',
              text: 'An error occurred while fetching form members.',
              buttons: ['Ok'],
              className: 'error-handler-modal'
            })
          })

    }
    console.log('formList', formList)

    return formList.map((form) => (
        <TableRow key={form.id} className='bg-white'>
            <TableCol className='checkbox-col'>
                <input type='checkbox' />
            </TableCol>
            <LinkCol to={`forms/${form.id}`}>
                {new Date(form.start_date).toDateString()}
            </LinkCol>
            <LinkCol to={`forms/${form.id}`}>
                {form.product}
            </LinkCol>
            <LinkCol to={`forms/${form.id}`}>
                {form.batch_number || "---"}
            </LinkCol>
            <LinkCol to={`forms/${form.id}`}>
                    <StatusComponent formStatus={form.status}></StatusComponent>
            </LinkCol>
            {adminUser && (
                <TableCol className="action-col">
                    <div className='dropleft'>
                        <button
                            className='btn dropdown-menu-lg-right'
                            type='button'
                            id='dropdownMenuButton'
                            data-toggle='dropdown'
                            aria-expanded='false'
                            >
                            <i className='fa-solid fa-ellipsis-vertical fa-xl' />
                        </button>
                        <div
                            className='dropdown-menu'
                            aria-labelledby='dropdownMenuButton'
                            >
                            <button className='dropdown-item' onClick={() => formMembers(form.id)} style={{ color: "gray" }}>
                                View Members
                            </button>
                            <a className='dropdown-item' href='#' onClick={() => archiveForm(form.id)}>
                                <i className='fas fa-trash-alt' style={{ color: "red" }}></i> &nbsp;{" "}
                                <span style={{ color: "red" }}>Delete</span>
                            </a>
                        </div>
                    </div>
                </TableCol>
            )}
        </TableRow>
    ));
}

const mapStateToProps = (state, ownProps): StateProps => ({
    ...ownProps,
    userList: state.userReducer.userList
})

export default connect(mapStateToProps)(TableBody);