import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { SectionTitle } from './SectionTitle'
import FieldList from './FieldList'
import { FormNavigator } from '../FormNavigator'
import { BatchAdjustmentForm } from '../BatchAdjustment'
import LoadingMessage from '../../LoadingMessage/LoadingMessage'
import useFormSection from '../../../hooks/useFormSection'
import { isAdmin } from '../../../utils/utils'
import { FormInstanceProps, FormTemplateProps } from '../../../formSchema'
import * as FormInstanceActions from '../../../actions/formInstanceAction'
import { SORT_BY_FIELD_INDEX } from '../../../utils/formUtils'

type StateProps = {
  submitForm: () => void
  user: any
  index: number
  formInstance: FormInstanceProps
  formTemplate: FormTemplateProps
  params: { [key: string]: any }
}

type DispatchProps = {
  formInstanceActions: typeof FormInstanceActions
}

type Props = StateProps & DispatchProps

const FormSection = ({
  user,
  formInstance,
  formTemplate,
  formInstanceActions,
  submitForm
}: Props) => {
  const {
    loading,
    section,
    fieldInstances,
    error,
    formIndex,
    setFormIndex,
    setFieldInstances,
    saving,
    standardYield,
    batchAdjust,
    handleFieldUpdate,
    validateSection,
    toggleBatchAdjust,
    addAdjustmentInstances
  } = useFormSection({
    formInstance,
    formTemplate,
    user,
    submitForm,
    formInstanceActions
  })

  if (loading || fieldInstances.length <= 0) return <LoadingMessage />

  const handleBack = () => {
    setFormIndex((prev: number) => prev - 1)
    // const prevSection = formTemplate.sections[formIndex - 1]
    // const prevFieldInstances = formInstance.field_instances
    //   .filter((instance) => instance.field.section_id === prevSection.id)
    //   .sort(SORT_BY_FIELD_INDEX)
    // setFieldInstances(prevFieldInstances)
  }

  const handleNext = () => {
    if (!validateSection()) return
    setFormIndex((prev: number) => prev + 1)
    // const nextSection = formTemplate.sections[formIndex + 1]
    // const nextFieldInstances = formInstance.field_instances
    //   .filter((instance) => instance.field.section_id === nextSection.id)
    //   .sort(SORT_BY_FIELD_INDEX)
    // setFieldInstances(nextFieldInstances)
  }

  if (batchAdjust) {
    return (
      <>
        <BatchAdjustmentForm
          batchAdjustmentFieldInstances={fieldInstances}
          formTemplate={formTemplate}
          handleFieldUpdate={handleFieldUpdate}
          addAdjustmentInstances={addAdjustmentInstances}
          onSave={handleFieldUpdate}
        />
        <FormNavigator
          index={1}
          maxIndex={1}
          onBack={toggleBatchAdjust}
          onSubmit={toggleBatchAdjust}
        >
          <div className="mr-4">
            {error && <p className="mb-0 text-danger">{error}</p>}
            <p className="mb-0">
              {saving ? (
                'Saving...'
              ) : (
                <>
                  Saved:{' '}
                  {moment(formInstance.updated_at).format('MM/DD/YYYY h:mm:ss a')}
                </>
              )}
            </p>
          </div>
        </FormNavigator>
      </>
    )
  }

  return (
    <>
      <section className="bg-white flex-fill">
        <div className="sop-form">
          <SectionTitle
            isAdmin={isAdmin(user)}
            section={section}
            showAdjustment={toggleBatchAdjust}
          />
          <FieldList
            fieldInstances={fieldInstances}
            standardYield={standardYield}
            handleFieldUpdate={handleFieldUpdate}
          />
        </div>
      </section>

      <FormNavigator
        index={formIndex}
        maxIndex={formTemplate.sections.length - 2}
        onBack={handleBack}
        onNext={handleNext}
        onSubmit={submitForm}
      >
        <div className="mr-4">
          {error && <p className="mb-0 text-danger">{error}</p>}
          <p className="mb-0">
            {saving ? (
              'Saving...'
            ) : (
              <>
                Saved:{' '}
                {moment(formInstance.updated_at).format('MM/DD/YYYY h:mm:ss a')}
              </>
            )}
          </p>
        </div>
      </FormNavigator>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  user: state.authReducer.user,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm
})

const mapDispatchToProps = (dispatch) => ({
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FormSection)