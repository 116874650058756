import React from 'react';
import { horizontalClasses } from '../FormFields';

interface TemplateValueProps {
    name: string
    value: string
    classes?: string
}


export const TemplateValue = ({name, value, classes}: TemplateValueProps) => (
    <div className={`form-input my-4 p-0 ${classes || "col-sm-6"} ${horizontalClasses}`}>
                <p className='mb-0'>
                    <b>{name}</b>:
                </p>
                <p className='mb-0'>{value}</p>
            </div>
)