export const formInstanceConstants = {

    CREATE_FORM_INSTANCE: 'CREATE_FORM_INSTANCE',
    CREATE_FORM_INSTANCE_FULFILLED: 'CREATE_FORM_INSTANCE_FULFILLED',

    UPDATE_FORM_INSTANCE: 'UPDATE_FORM_INSTANCE',
    UPDATE_FORM_INSTANCE_FULFILLED: 'UPDATE_FORM_INSTANCE_FULFILLED',

    FETCH_FORM_INSTANCE: 'FETCH_FORM_INSTANCE',
    FETCH_FORM_INSTANCE_FULFILLED: 'FETCH_FORM_INSTANCE_FULFILLED',

    FETCH_FORM_INSTANCES: 'FETCH_FORM_INSTANCES',
    FETCH_FORM_INSTANCES_FULFILLED: 'FETCH_FORM_INSTANCES_FULFILLED',

    ARCHIVE_FORM_INSTANCE: 'ARCHIVE_FORM_INSTANCE',
    ARCHIVE_FORM_INSTANCE_FULFILLED: 'ARCHIVE_FORM_INSTANCE_FULFILLED',

    FETCH_FORM_INSTANCE_SECTION: 'FETCH_FORM_INSTANCE_SECTION',
    FETCH_FORM_INSTANCE_SECTION_FULFILLED: 'FETCH_FORM_INSTANCE_SECTION_FULFILLED',
};