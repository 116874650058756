import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/scss/bootstrap.scss"
import "react-datetime/css/react-datetime.css"
import './styles/style.scss';
import './styles/fontawesome-free-6.1.1-web/css/all.css'
import axios from 'axios';
import {browserHistory, Route, Router, IndexRoute} from 'react-router';
import {Provider} from 'react-redux';
import {FlagsProvider} from '@empiric/empiric-flagged-react';
import {NotFound} from './components/NotFound';
import store from './store/store';
import Login from './containers/Login/Login';
import SignUp from './containers/SignUp/SignUp';
import skipAuth from './utils/skipAuth';
import storage from './utils/storage';
import {gatewayData, inactiveDevices} from './utils/deviceDataHelper';
import {organizationData} from './utils/organizationDataHelper';
import {wifiData} from './utils/wifiDataHelper';
import {userData} from './utils/fetchUserData';
import {fetchScheduledReport} from './utils/fetchDataHelper';
import {getAllSensors} from './utils/fetchSensorData.js';
import authInterceptor from './utils/interceptors/authInterceptor';
import tokenInterceptor from './utils/interceptors/tokenInterceptor';
import errorHandler from './utils/interceptors/errorHandler';

import {unregister} from './registerServiceWorker';
import GroupsAndDevicesContainer from './containers/GroupsAndDevicesContainer/GroupsAndDevicesContainer';
import GroupFormContainer from './containers/GroupFormContainer/GroupFormContainer';
import EditGroupFormContainer from './containers/GroupFormContainer/EditGroupFormContainer';
import DeviceFormContainer from './containers/DeviceFormContainer/DeviceFormContainer';
import CreateAlertPageContainer from './containers/CreateAlertPageContainer/CreateAlertPageContainer';
import EditAlertPageContainer from './containers/EditAlertPageContainer/EditAlertPageContainer';
import IncidentsPageContainer from './containers/IncidentsPageContainer/IncidentsPageContainer';
import CreateIncidentReportContainer from './containers/CreateIncidentReportContainer/CreateIncidentReportContainer';
import EditIncidentReportContainer from './containers/EditIncidentReportContainer/EditIncidentReportContainer';
import AlertsPageContainer from './containers/AlertsPageContainer/AlertsPageContainer';
import EnvironmentDashboardContainer from './containers/EnvironmentPageContainer/EnvironmentDashboardContainer';
import EditWorkflowScheduleContainer from "./containers/EditWorkflowScheduleContainer/EditWorkflowScheduleContainer";
import EnvironmentDeviceContainer from './containers/EnvironmentPageContainer/EnvironmentDeviceContainer.js';
import DevicesMapOverviewContainer from './containers/DevicesMapContainer/DevicesMapOverviewContainer.js';
import GeneralSettingContainer from './containers/SettingPageContainer/GeneralSettingContainer.js';
import UserSettingContainer from './containers/SettingPageContainer/UserSettingContainer.js';
import WifiSettingContainer from './containers/SettingPageContainer/WifiSettingContainer.js';
import ProbesContainer from './containers/SettingPageContainer/ProbesContainer.js';
import SensorSettingContainer from './containers/SettingPageContainer/SensorSettingContainer.js';
import GatewaySettingContainer from './containers/SettingPageContainer/GatewaySettingContainer.js';
import ScheduledReportSettingContainer from './containers/SettingPageContainer/ScheduledReportSettingContainer.js';
import GatewayPageContainer from './containers/SettingPageContainer/GatewayPageContainer.js';
import SensorPageContainer from './containers/SettingPageContainer/SensorPageContainer.js';
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import UpdatePassword from "./containers/ChangePassword/UpdatePassword";
import LoaderContainer from "./containers/LoaderContainer/LoaderContainer";
import NewAlertContainer from "./containers/NewAlertContainer/NewAlertContainer";
import requireAuth from './utils/requireAuth';
import IncidentsDetailPageContainer from "./containers/IncidentDetailPageContainer/IncidentsDetailPageContainer";
import SchemaRegistryContainer from "./containers/SchemaRegistryContainer/SchemaRegistryContainer";
import SchemaRegistryFormContainer from "./containers/SchemaRegistryContainer/SchemaRegistryFormContainer";
import CreateReportContainer from "./containers/CreateReportPageContainer/CreateReportContainer";
import AuditLogContainer from "./containers/AuditLog/AuditLogContainer";
import {getExperimentTreatmentByUser} from "./api/experimentApi";

import NewEnvironmentDashboardContainer from './containers/EnvironmentPageContainer/NewEnvironmentDashboardContainer';
import NewEnvironmentDashboardDetailContainer
    from './containers/EnvironmentPageContainer/NewEnvironmentDashboardDetailContainer';
import EditWidgetFormContainer from './containers/EnvironmentPageContainer/EditWidgetFormContainer';
import CreateWidgetFormContainer from './containers/EnvironmentPageContainer/CreateWidgetFormContainer';
import ScheduledJobListContainer from './containers/ScheduledJobListContainer/ScheduledJobListContainer';
// import ShipmentsContainer from "./containers/ShipmentsContainer/ShipmentsContainer";
import WorkflowPageContainer from "./containers/WorkflowPageContainer/WorkflowPageContainer";
import WorkflowScheduleDetailContainer
    from "./containers/WorkflowScheduleDetailContainer/WorkflowScheduleDetailContainer";
import CreateWorkflowScheduleContainer
    from "./containers/CreateWorkflowScheduleContainer/CreateWorkflowScheduleContainer";
// import ShipmentContactsContainer from "./containers/ShipmentContactsContainer/ShipmentContactsContainer";

import {LoadScript} from "@react-google-maps/api";
import {GMAPS_API_KEY} from "./constants/geomap.constants";
import UnlockAccount from "./containers/UnlockAccount/UnlockAccount";
// import ShipmentDetailPageContainer from "./containers/ShipmentDetailPageContainer/ShipmentDetailPageContainer";
// import ShipmentInspectionDetailPageContainer from "./containers/ShipmentInspectionDetailPageContainer/ShipmentInpsectionDetailPageContainer";
// import ShipmentCreateContainer from "./containers/ShipmentCreateContainer/ShipmentCreateContainer";
import ScheduledJobDetailContainer from "./containers/ScheduledJobDetailContainer/ScheduledJobDetailContainer";
import AssetDetailPageContainer from "./containers/AssetDetailPageContainer/AssetDetailPageContainer";
import AssetEditDetailPageContainer from "./containers/AssetEditDetailPageContainer/AssetEditDetailPageContainer";
import AssetCreateDetailPageContainer from "./containers/AssetCreateDetailPageContainer/AssetCreateDetailPageContainer";
import AssetsContainer from "./containers/AssetContainer/AssetsContainer";
import AssetTypeEditDetailPageContainer from "./containers/AssetTypeEditDetailPageContainer/AssetTypeEditDetailPageContainer";
import AssetTypeCreateDetailPageContainer from "./containers/AssetTypeCreateDetailPageContainer/AssetTypeCreateDetailPageContainer";
import AssetTypesContainer from "./containers/AssetTypesContainer/AssetTypesContainer";
import AssetTypeDetailPageContainer from "./containers/AssetTypeDetailPageContainer/AssetTypeDetailPageContainer";
import DeviceDetailPageContainer from "./containers/DeviceDetailPageContainer/DeviceDetailPageContainer";
import DeviceEditDetailPageContainer from "./containers/DeviceEditDetailPageContainer/DeviceEditDetailPageContainer";
import AreaDetailPageContainer from "./containers/AreaDetailPageContainer/AreaDetailPageContainer";
import AreasContainer from "./containers/AreasContainer/AreasContainer";
import AreaEditDetailPageContainer from "./containers/AreaEditDetailPageContainer/AreaEditDetailPageContainer";
import AreaCreateDetailPageContainer from "./containers/AreaCreateDetailPageContainer/AreaCreateDetailPageContainer";
import AreaTypeDetailPageContainer from "./containers/AreaTypeDetailPageContainer/AreaTypeDetailPageContainer";
import AreaTypesContainer from "./containers/AreaTypesContainer/AreaTypesContainer";
import AreaTypeEditDetailPageContainer from "./containers/AreaTypeEditDetailPageContainer/AreaTypeEditDetailPageContainer";
import AreaTypeCreateDetailPageContainer from "./containers/AreaTypeCreateDetailPageContainer/AreaTypeCreateDetailPageContainer";
import WorkEventContainer from "./containers/WorkEventContainer/WorkEventContainer";
import DeviceTypesContainer from "./containers/DeviceTypesContainer/DeviceTypesContainer";
import DeviceTypeCreateDetailPageContainer
    from "./containers/DeviceTypeCreateDetailPageContainer/DeviceTypeCreateDetailPageContainer";
import DeviceTypeDetailPageContainer from "./containers/DeviceTypeDetailPageContainer/DeviceTypeDetailPage";
import DeviceTypeEditDetailPageContainer
    from "./containers/DeviceTypeEditDetailPageContainer/DeviceTypeEditDetailPageContainer";
import DevicesContainer from "./containers/DevicesContainer/DevicesContainer";
import TablesContainer from "./containers/TablesContainer/TablesContainer";
import TablesDetailPageContainer from "./containers/TablesContainer/TablesDetailPageContainer";
import SOPDashboardPage from './components/SOPDashboardPage/SOPDashboardPage';
import CreateSOPFormPage from './components/CreateSOPFormPage/CreateSOPFormPage';
import SOPFormPageContainer from './containers/SOPFormPageContainer/SOPFormPageContainer';
import SOPFormPage from './components/SOPFormPage/SOPFormPage';

const LIBRARIES = ["drawing", "places"];

axios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
axios.interceptors.response.use(tokenInterceptor, errorHandler);

(async function () {

    async function getExperiments() {
        try {
            let experiments = await Promise.all([
                    getExperimentTreatmentByUser("DATA_INTEGRATIONS_FEATURE_3784ca3378ac"),
                    getExperimentTreatmentByUser("GEOFENCE_FEATURE_3784ca3378ac"),
                    getExperimentTreatmentByUser("NEW_DASHBOARD_FEATURE_3784ca3378ac"),
                    getExperimentTreatmentByUser("DEVICE_MAP_FEATURE_b5b22654f54c"),
                    getExperimentTreatmentByUser("SHIPMENTS_FEATURE_c9a51153d59c"),
                    getExperimentTreatmentByUser("PROBES_FEATURE_2z1vcp3278bi"),
                    getExperimentTreatmentByUser("LANDINGPAGE_FEATURE_3784ca3378ac"),
                    getExperimentTreatmentByUser("ASSETS_FEATURE_1bc8fa1348ac")
                ]
            );

            if (!experiments[0].clientId) return null;

            experiments = experiments.reduce((map, obj) => {
                map[obj.featureFlagId] = obj;
                return map;
            }, {});
            return experiments
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    let experiments = null;

    if (storage.get('token')) {
        experiments = await getExperiments();
    }

    let features;

    if (!experiments) {
        features = {
            deviceMapExperiment: false,
            dataIntegrationExperiment: false,
            geofenceExperiment: false,
            shipmentsExperiment: false,
            probeExperiment: false,
            landingPageExperiment: false,
            assetsExperiment: false
        }
    } else {
        features = {
            deviceMapExperiment: experiments.DEVICE_MAP_FEATURE_b5b22654f54c.treatment === "T1",
            dataIntegrationExperiment: experiments.DATA_INTEGRATIONS_FEATURE_3784ca3378ac.treatment === "T1",
            geofenceExperiment: experiments.GEOFENCE_FEATURE_3784ca3378ac.treatment === "T1",
            newDashboardExperiment: experiments.NEW_DASHBOARD_FEATURE_3784ca3378ac.treatment === "T1",
            shipmentsExperiment: experiments.SHIPMENTS_FEATURE_c9a51153d59c.treatment === "T1",
            probeExperiment: experiments.PROBES_FEATURE_2z1vcp3278bi.treatment === "T1",
            landingPageExperiment: experiments.LANDINGPAGE_FEATURE_3784ca3378ac.treatment === "T1",
            assetsExperiment: experiments.ASSETS_FEATURE_1bc8fa1348ac.treatment === "T1"
        }
    }

    const landingPageRoute = features.landingPageExperiment ?
        <Route exact path="/" component={NewEnvironmentDashboardContainer} onEnter={requireAuth}/> :
        <Route exact path="/" component={EnvironmentDashboardContainer} onEnter={requireAuth}/>

    ReactDOM.render(
        <Provider store={store}>
            <FlagsProvider features={features}>
                <LoadScript
                    id='script-loader'
                    googleMapsApiKey={GMAPS_API_KEY}
                    language='en'
                    region='us'
                    onError={(e) => console.log(e)}
                    libraries={LIBRARIES}
                >
                    <Router history={browserHistory} onUpdate={() => window.scrollTo(0, 0)}>
                        {landingPageRoute}
                        <Route path='auth' onEnter={skipAuth}>
                            <Route path='login' component={Login} />
                            <Route path='register' component={SignUp} />
                            <Route path='forgot_password' component={ForgotPassword} />
                            <Route path='change_password' component={ChangePassword} />
                            <Route path='unlock_account' component={UnlockAccount} />
                        </Route>
                        <Route path='areas' component={AreasContainer} onEnter={requireAuth} />
                        <Route path='areas/new' component={AreaCreateDetailPageContainer} onEnter={requireAuth} />
                        <Route path='areas/:id' component={AreaDetailPageContainer} onEnter={requireAuth} />
                        <Route path='areas/:id/edit' component={AreaEditDetailPageContainer} onEnter={requireAuth} />
                        <Route path='area_types' component={AreaTypesContainer} onEnter={requireAuth} />
                        <Route
                            path='area_types/new'
                            component={AreaTypeCreateDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='area_types/:id' component={AreaTypeDetailPageContainer} onEnter={requireAuth} />
                        <Route
                            path='area_types/:id/edit'
                            component={AreaTypeEditDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='assets' component={AssetsContainer} onEnter={requireAuth} />
                        <Route path='assets/new' component={AssetCreateDetailPageContainer} onEnter={requireAuth} />
                        <Route path='assets/:asset_id' component={AssetDetailPageContainer} onEnter={requireAuth} />
                        <Route
                            path='assets/:asset_id/edit'
                            component={AssetEditDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='asset_types' component={AssetTypesContainer} onEnter={requireAuth} />
                        <Route
                            path='asset_types/new'
                            component={AssetTypeCreateDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='asset_types/:id' component={AssetTypeDetailPageContainer} onEnter={requireAuth} />
                        <Route
                            path='asset_types/:asset_type_id/edit'
                            component={AssetTypeEditDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='databases/:database_name/tables'
                            component={TablesContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='databases/:database_name/tables/:id'
                            component={TablesDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='device_types' component={DeviceTypesContainer} onEnter={requireAuth} />
                        <Route
                            path='device_types/new'
                            component={DeviceTypeCreateDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='device_types/:id'
                            component={DeviceTypeDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='device_types/:device_type_id/edit'
                            component={DeviceTypeEditDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='update_password' component={UpdatePassword} />
                        <Route path='event' component={LoaderContainer} />
                        {/*<Route path="setup_wizard" component={SetupWizardContainer}  onEnter={requireAuth } />*/}
                        <Route path='groups_devices' component={GroupsAndDevicesContainer} onEnter={requireAuth} />
                        <Route path='new_group' component={GroupFormContainer} onEnter={requireAuth} />
                        <Route path='group/:id' component={EditGroupFormContainer} onEnter={requireAuth} />



                        {/* FORMS */}
                        <Route path='forms' onEnter={requireAuth} component={SOPFormPageContainer}>
                            <IndexRoute component={SOPDashboardPage}/>
                            <Route path='assign' component={CreateSOPFormPage} onEnter={userData} />
                            <Route path=':id' component={SOPFormPage} onEnter={userData} />
                        </Route>
                        {/* FORMS */}


                        <Route path='devices' component={DevicesContainer} onEnter={requireAuth} />
                        <Route path='devices/:id' component={DeviceDetailPageContainer} onEnter={requireAuth} />
                        <Route
                            path='devices/:id/edit'
                            component={DeviceEditDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='new_device'
                            component={DeviceFormContainer}
                            onEnter={requireAuth && inactiveDevices}
                        />
                        {/*<Route path="new_report" component={ReportFormContainer}  onEnter={requireAuth } />*/}
                        <Route path='incidents' component={IncidentsPageContainer} onEnter={requireAuth} />
                        <Route
                            path='incidents/:incident_id'
                            component={IncidentsDetailPageContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='incidents/:incident_id/report/new'
                            component={CreateIncidentReportContainer}
                            onEnter={requireAuth}
                        />
                        <Route
                            path='incidents/:incident_id/report/:id/edit'
                            component={EditIncidentReportContainer}
                            onEnter={requireAuth}
                        />
                        <Route path='alerts' component={AlertsPageContainer} onEnter={requireAuth} />
                        {/*<Route path="support" component={SupportPageContainer}  onEnter={requireAuth } />*/}
                        <Route path='create_alert' component={CreateAlertPageContainer} onEnter={requireAuth} />
                        <Route path='alerts/:id/edit' component={EditAlertPageContainer} onEnter={requireAuth} />
                        {/*<Route path="profile" component={UserProfileContainer}  onEnter={requireAuth } />*/}
                        <Route path='temperature_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='connection_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='battery_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='humidity_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='metric_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='geofence_alert' component={NewAlertContainer} onEnter={requireAuth} />
                        <Route path='settings' onEnter={requireAuth}>
                            <Route path='general' component={GeneralSettingContainer} onEnter={organizationData} />
                            <Route path='users' component={UserSettingContainer} onEnter={userData} />
                            <Route path='wifi' component={WifiSettingContainer} onEnter={wifiData} />
                            <Route path='probes' component={ProbesContainer} />
                            <Route path='sensor' component={SensorSettingContainer} onEnter={getAllSensors} />
                            <Route path='gateway' component={GatewaySettingContainer} onEnter={gatewayData} />
                            {/*<Route path="api_keys" component={ApiKeysSettingContainer}  onEnter={apiKeysData}/>*/}
                            <Route path='sensor/:id' component={SensorPageContainer} />
                            <Route path='gateway/:id' component={GatewayPageContainer} />
                            <Route
                                path='schedule_report'
                                component={ScheduledReportSettingContainer}
                                onEnter={fetchScheduledReport}
                            />
                            {/*<Route path="device_map" component={DeviceMapSettingContainer} />*/}
                        </Route>
                        <Route path='work_events' onEnter={requireAuth}>
                            <Route path='new' component={WorkEventContainer} />
                        </Route>
                        <Route path='environment' onEnter={requireAuth}>
                            <Route path='dashboard' component={EnvironmentDashboardContainer} />
                            <Route path='custom_dashboard' component={NewEnvironmentDashboardContainer} />
                            <Route path='custom_dashboard/:id' component={NewEnvironmentDashboardDetailContainer} />
                            <Route
                                path='custom_dashboard/:id/widgets/:widgetId/edit'
                                component={EditWidgetFormContainer}
                            />
                            <Route
                                path='custom_dashboard/:id/widgets/create_new_widget'
                                component={CreateWidgetFormContainer}
                            />
                            <Route path='device/:id' component={EnvironmentDeviceContainer} />
                        </Route>
                        <Route path='reports' onEnter={requireAuth}>
                            <Route path='create_report' component={CreateReportContainer} />
                        </Route>
                        <Route path='audits' onEnter={requireAuth} component={AuditLogContainer} />

                        <Route path='workflows' onEnter={requireAuth} component={WorkflowPageContainer}></Route>
                        <Route
                            path='workflows/create_workflow_schedule'
                            onEnter={requireAuth}
                            component={CreateWorkflowScheduleContainer}
                        />
                        <Route path='workflow_schedules' onEnter={requireAuth}>
                            <Route path=':scheduleId' component={WorkflowScheduleDetailContainer} />
                            <Route path=':scheduleId/edit' component={EditWorkflowScheduleContainer} />
                        </Route>
                        <Route
                            path='scheduled_jobs'
                            onEnter={requireAuth}
                            component={ScheduledJobListContainer}
                        ></Route>
                        <Route
                            path='scheduled_jobs/:scheduledJobId'
                            onEnter={requireAuth}
                            component={ScheduledJobDetailContainer}
                        ></Route>
                        <Route path='devicesmap' onEnter={requireAuth}>
                            <Route path='overview' component={DevicesMapOverviewContainer} />
                        </Route>
                        {/*<Route path="shipments" onEnter={requireAuth} component={ShipmentsContainer}/>*/}
                        {/*<Route path="shipments/create_shipment" onEnter={requireAuth}*/}
                        {/*       component={ShipmentCreateContainer}/>*/}
                        {/*<Route path="shipments/:shipmentId" onEnter={requireAuth}*/}
                        {/*       component={ShipmentDetailPageContainer}/>*/}
                        {/*<Route path="shipments/:shipmentId/inspections/:inspectionId" onEnter={requireAuth}*/}
                        {/*       component={ShipmentInspectionDetailPageContainer}/>*/}
                        {/*<Route path="shipment_contacts" onEnter={requireAuth} component={ShipmentContactsContainer}/>*/}
                        <Route path='data' onEnter={requireAuth}>
                            <Route path='schemas' component={SchemaRegistryContainer} />
                            <Route path='schemas/create' component={SchemaRegistryFormContainer} />
                        </Route>
                        <Route path='*' component={NotFound} />
                    </Router>
                </LoadScript>
            </FlagsProvider>
        </Provider>,
        document.getElementById("root")
    );
})()


unregister();
//registerServiceWorker();