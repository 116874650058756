import React from "react"

export const SectionTitle = ({isAdmin, section, showAdjustment}) => {
    return (
        <div className='border-bottom pb-3 mb-4 d-flex justify-content-between align-items-center'>
            <div>
                {/* Batch Info */}
                {section.section_name === "Batch Information" && (
                    <p className='sop-section-title'>{section.section_name}</p>
                )}
                {/* Ingredients */}
                {section.section_name === "Ingredients" && (
                    <>
                        <p className='sop-section-title sop-section-title--sm'>{section.section_name}</p>
                        <p className='sop-section-subtitle'>{section.section_description}</p>
                    </>
                )}

                {/* "Steps" */}
                {!["Batch Information", "Ingredients"].includes(section.section_name) && (
                    <>
                        <p className='sop-section-title sop-section-title--sm text-primary'>{section.section_name}</p>
                        <p className='sop-section-subtitle'>{section.section_description}</p>
                    </>
                )}
            </div>

            {(isAdmin || section.show_adjustment_btn) && <button onClick={showAdjustment} className='btn btn-primary'>Add Adjustments</button>}
        </div>
    );
}