import React from "react";
import { horizontalClasses } from "../FormFields";
import Select from "react-select";
import { FieldError } from "./FieldError";

interface SOPSelectProps {
    options: any
    onChange: (value: string) => void
    label?: string
    orientation?: string
    classes?: string
    value?: string
    error?: string
}

export const SOPSelect = ({value, options, onChange, label, orientation, classes, error}: SOPSelectProps) => {
    return (
      <div
        className={`form-input my-4 px-0  ${classes || 'col-sm-6'} ${
          orientation === 'horizontal' && horizontalClasses
        }`}
      >
        {label && <label>{label}:</label>}

        <Select
          options={options.value.map((option) => ({
            value: option,
            label: option
          }))}
          value={
            value
              ? { value: value, label: value }
              : { value: '', label: '-Select-' }
          }
          // TODO : Handle select change uniquely
          onChange={(value) => onChange(value?.value || '')}
          placeholder="-Select-"
          className="form-group__field form-control-input"
          classNames={{
            control: () => error ? 'border border-danger' : ''
          }}
        />
        {error && <FieldError error={error} />}
      </div>
    )
}